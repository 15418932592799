<template>
	<v-container id="active-loans" fluid tag="section">
		<v-row
			><v-col cols="12" sm="6" lg="3">
				<base-material-stats-card
					color="success"
					icon="mdi-chart-donut"
					title="Total Active"
					value="132"
				/>
			</v-col>
			<v-col cols="12" sm="6" lg="3">
				<base-material-stats-card
					color="primary"
					icon="mdi-chart-donut"
					title="Total Amt(ZMW)"
					value="76430.00"
				/>
			</v-col>

			<v-col cols="12" sm="6" lg="3">
				<base-material-stats-card
					color="red"
					icon="mdi-alert"
					title="Risk Amt(ZMW)"
					value="9171.60"
				/>
			</v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="orange"
          icon="mdi-alert-circle"
          title="Risk %"
          value="12%"
        />
      </v-col>
      <v-col cols="12" md="12">
        <v-row rows="12" align="right" justify="end">
          <div class="my-2">
            <template>
              <v-dialog
                v-model="dialog"
                max-width="800px"
                transition="dialog-top-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="9"
                    color="primary"
                    x-large
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left>mdi-plus-circle</v-icon>
                    Issue Loan
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dark color="primary">
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.lname"
                            label="Customer"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.fname"
                            label="Tenure"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.phone"
                            label="Amount(ZMW)"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn color="primary" text @click="close"> Cancel </v-btn>
										<v-btn color="primary" text @click="save"> Save </v-btn>
									</v-card-actions>
								</v-card>
							</v-dialog>
							<v-dialog v-model="dialogDelete" max-width="500px">
								<v-card>
									<v-card-title class="text-h5"
										>Are you sure you want to delete this item?</v-card-title
									>
									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn color="primary" text @click="closeDelete"
											>Cancel</v-btn
										>
										<v-btn color="primary" text @click="deleteItemConfirm"
											>OK</v-btn
										>
										<v-spacer></v-spacer>
									</v-card-actions>
								</v-card>
							</v-dialog>
						</template>
					</div>
				</v-row>
			</v-col>

			<v-col cols="12" md="12">
				<base-material-card color="primary" class="px-5 py-3">
					<template v-slot:heading>
						<div class="display-2 font-weight-dark lightgray--text">
							Active Loans
							<button class="float-right">Download</button>
							<v-spacer />
							<v-text-field
								v-model="search"
								append-icon="mdi-magnify"
								label="Search"
								single-line
								hide-details
								color="lightgray"
							/>
						</div>

						<div class="float-right white--text">
							<v-switch
								v-model="singleExpand"
								color="#ffffff"
								label="Single Expand"
								class="mt-2"
							></v-switch>
						</div>
					</template>
					<v-card-text>
						<v-data-table
							:headers="headers"
							:items="items"
							:search="search"
							:single-expand="singleExpand"
							:expanded.sync="expanded"
							item-key="id"
							:sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc"
							show-expand
							class="elevation-1"
							@click:row="(item, slot) => slot.expand(!slot.isExpanded)"
						>
							<template v-slot:item.aging="{ item }">
								<div
									:class="{
										'red--text font-weight-medium': item.aging < 0,
										'green--text font-weight-medium': item.aging > 0,
									}"
								>
									{{ item.aging }}
								</div>
							</template>
							<template v-slot:item.amount="{ item }">
								<div :class="' font-weight-regular'">
									K{{ item.amount }}
								</div>
							</template>
							<template v-slot:[`item.actions`]="{ item }">
								<v-icon small class="mr-2" @click="editItem(item)">
									mdi-pencil
								</v-icon>
								<v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
							</template>
							<template v-slot:no-data>
								<v-btn color="primary" @click="initialize"> Reset </v-btn>
							</template>
							<template v-slot:expanded-item="{ headers, item }">
								<td :colspan="headers.length">
									<v-spacer />
									More info about {{ item.id }}
									<v-spacer />
									More info about {{ item.lname }}
									<v-spacer>
										More info about {{ item.fname }}
										<v-spacer />
										More info about {{ item.organization }}
										<v-spacer />
										Gender {{ item.gender }}
									</v-spacer>
									<v-spacer />
								</td>
							</template>
						</v-data-table>
					</v-card-text>
				</base-material-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
	export default {
		data: () => ({
			dialog: false,
			dialogDelete: false,
			sortBy: "id",
			sortDesc: true,
			search: "",
			expanded: [],
			singleExpand: false,
			headers: [
				{
					sortable: true,
					text: "Issued Date",
					value: "date",
				},
				{
					sortable: true,
					text: "Due Date",
					value: "dueDate",
				},
				{
					sortable: true,
					text: "Name",
					value: "name",
				},
				{
					sortable: true,
					text: "Phone",
					value: "phone",
				},
				{
					sortable: true,
					text: "Days",
					value: "aging",
				},
				{
					sortable: true,
					text: "Principal",
					value: "amount",
					class: "font-weight-black",
				},
				{
					sortable: true,
					text: "Amount",
					value: "amount",
					class: "font-weight-black",
				},
				// { text: "Actions", value: "actions", sortable: false, align: "right" },
				{ text: "", value: "data-table-expand" },
			],
			items: [],
			editedIndex: -1,
			editedItem: {
				createdAt: "",
				lname: "",
				fname: "",
				phone: "",
				email: "",
				organization: "",
			},
			defaultItem: {
				createdAt: "",
				lname: "",
				fname: "",
				phone: "",
				email: "",
				organization: "",
			},
		}),

		computed: {
			formTitle() {
				return this.editedIndex === -1 ? "Issue Loan" : "Edit Loan";
			},
		},

		watch: {
			dialog(val) {
				val || this.close();
			},
			dialogDelete(val) {
				val || this.closeDelete();
			},
		},

		created() {
			this.initialize();
		},

		methods: {
			initialize() {
				this.items = [
					{
						id: 1,
						name: "Mwerenga Nicky",
						phone: "260974327259",
						amount: "200",
						aging: "-2",
						dueDate: "2020/12/23",
						date: "2020/12/23",
					},
					{
						id: 2,
						name: "Solomon Leonard",
						phone: "260950637780",
						amount: "62",
						aging: "29",
						dueDate: "2020/03/23",
						date: "2020/02/23",
					},
					{
						id: 3,
						name: "Saunda Benard",
						phone: "26095929292",
						amount: "8932",
						aging: "30",
						dueDate: "2021/04/28",
						date: "2021/03/28",
					},
					{
						id: 4,
						name: "Nyawela Patrick",
						phone: "26096728273",
						amount: "1000",
						aging: "-2",
						dueDate: "2021/03/08",
						date: "2021/02/08",
					},
					{
						id: 5,
						name: "Sekerere Ronny",
						phone: "26077826372",
						amount: "700",
						aging: "-8",
						dueDate: "2020/02/01",
						date: "2020/02/01",
					},
					{
						id: 6,
						name: "Solomon Leonard",
						phone: "26075102912",
						amount: "12",
						aging: "10",
						dueDate: "2021/04/14",
						date: "2021/03/14",
					},
				];
			},

			editItem(item) {
				this.editedIndex = this.items.indexOf(item);
				this.editedItem = Object.assign({}, item);
				this.dialog = true;
			},

			deleteItem(item) {
				this.editedIndex = this.items.indexOf(item);
				this.editedItem = Object.assign({}, item);
				this.dialogDelete = true;
			},

			deleteItemConfirm() {
				this.items.splice(this.editedIndex, 1);
				this.closeDelete();
			},

			close() {
				this.dialog = false;
				this.$nextTick(() => {
					this.editedItem = Object.assign({}, this.defaultItem);
					this.editedIndex = -1;
				});
			},

			closeDelete() {
				this.dialogDelete = false;
				this.$nextTick(() => {
					this.editedItem = Object.assign({}, this.defaultItem);
					this.editedIndex = -1;
				});
			},

			save() {
				if (this.editedIndex > -1) {
					Object.assign(this.items[this.editedIndex], this.editedItem);
				} else {
					this.items.push(this.editedItem);
				}
				this.close();
			},
		},
		mounted() {
			document.title = "Active Loans | Kredwise";
		},
	};
</script>

<style lang="scss" scoped></style>
